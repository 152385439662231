import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PopUpPassword from '../components/adhesion/PopUpPassword';
import { getContactByAdhId } from '../api/apiBack';

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: '#d8e9d1',
    boxShadow: '1px 2px 5px grey',
  },
  center: {
    textAlign: 'center',
    marginTop: 10,
  },
  contactContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    marginBottom: '30px',
  },
  titlePage: {
    textAlign: 'center',
    backgroundColor: '#1E6634',
    borderRadius: '0 0 20px 20px',
    margin: '0 80px',
    height: '50%',
    color: 'white',
    paddingTop: '20px',
  },
  intro: {
    textAlign: 'center',
    fontSize: '1rem',
    marginBottom: '30px',
    marginTop: '15px',
  },
  subtitle: {
    textAlign: 'center',
    fontSize: '1.5rem',
    color: '#1E6634',
    marginBottom: 0,
  },
  noMarginBottom: {
    marginBottom: 0,
  },
  noMarginTop: {
    marginTop: 0,
  },
}));

export default function Adhesion() {
  const classes = useStyles();
  const [contacts, setContacts] = useState([]);
  const adherent = useSelector((state) => state.adherent.adherent);

  // const token = localStorage.getItem('TOKEN');

  useEffect(() => {
    getContactByAdhId(adherent.id).then((results) => {
      setContacts(results.data);
    });
  }, []);

  return (
    <div>
      <h2 className={classes.titlePage}>MON ADHÉSION</h2>
      <div>
        <p className={classes.intro}>
          Retrouvez vos coordonées et vos informations personelles.
        </p>
        {/* <p>
          Des champs sont vides ? Remplissez-les ! Nous souhaitons en savoir
          plus sur vous.
        </p> */}
      </div>
      <div>
        <p className={classes.subtitle}>IDENTITÉ ET COORDONNÉES</p>
        <Paper>
          <Grid className={classes.container}>
            <h3 style={{ marginBottom: 0 }} className={classes.center}>
              Identité de la structure
            </h3>
            <p style={{ marginTop: 0 }} className={classes.center}>
              {adherent.sign}
            </p>
            <Grid className={classes.contactContainer}>
              {contacts.map((contact) => (
                <Grid>
                  <h3 className={classes.noMarginBottom}>
                    Nom et prénom du référent
                  </h3>
                  <p className={classes.noMarginTop}>
                    {contact.lastname} {contact.firstname}
                  </p>
                  <h3 className={classes.noMarginBottom}>Adresse e-mail</h3>
                  <p className={classes.noMarginTop}>{contact.email}</p>
                  <h3 className={classes.noMarginBottom}>
                    Numéro de téléphone #1
                  </h3>
                  <p className={classes.noMarginTop}>{contact.phone01}</p>
                  <h3 className={classes.noMarginBottom}>
                    Numéro de téléphone #2
                  </h3>
                  <p className={classes.noMarginTop}>{contact.phone02}</p>
                  <h3 className={classes.noMarginBottom}>Fonction</h3>
                  <p className={classes.noMarginTop}>{contact.contactType}</p>
                </Grid>
              ))}
            </Grid>
            <PopUpPassword />
          </Grid>
        </Paper>
      </div>
    </div>
  );
}
