import axios from 'axios';
import { URL_API } from '../env';

const BASE_URL = `${URL_API}`;

/**
 *
 * @param {string} url route url
 */
function axiosGetPromise(url) {
  const token = localStorage.getItem('TOKEN');
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  return new Promise((resolve, reject) => {
    axios
      .get(url, config)
      .then((response) => resolve(response))
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 *
 * @param {string} url route url
 */
function axiosGet(url) {
  const token = localStorage.getItem('TOKEN');
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  return axios.get(url, config);
}

function axiosPutPromise(url, datas) {
  const token = localStorage.getItem('TOKEN');
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  return new Promise((resolve, reject) => {
    axios
      .put(url, datas, config)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export function getAllStatus() {
  const url = BASE_URL.concat('status');

  return axiosGetPromise(url);
}

export function getAllAdherents() {
  const url = BASE_URL.concat('adherent');

  return axiosGetPromise(url);
}

export function getOneAdherent(id) {
  const url = BASE_URL.concat(`adherent/${id}`);

  return axiosGetPromise(url);
}

export function AdherentsDistinctZipCode() {
  const url = BASE_URL.concat('adherent/distinctZipCode');

  return axiosGetPromise(url);
}

export function getAllCivility() {
  const url = BASE_URL.concat('civility');

  return axiosGetPromise(url);
}

export function getAllDistanceType() {
  const url = BASE_URL.concat('distanceType');

  return axiosGetPromise(url);
}

export function getAllTypology() {
  const url = BASE_URL.concat('typology');

  return axiosGetPromise(url);
}

export function getAllemployeeQuantity() {
  const url = BASE_URL.concat('employeeQuantity');

  return axiosGetPromise(url);
}

export function getAdherentZipCode(adherentZipCode) {
  const url = BASE_URL.concat(`zipCode/bycp/${adherentZipCode}`);

  return axiosGetPromise(url);
}

export function getContactByAdherentId(adherentId) {
  const url = BASE_URL.concat(`contact/byadhid/${adherentId}`);

  return axiosGetPromise(url);
}

export function getContactByAdhId(adherentId) {
  const url = BASE_URL.concat(`adherent/${adherentId}/contacts`);

  return axiosGetPromise(url);
}

export function putAdherent(adherent) {
  const url = BASE_URL.concat(`adherent/${adherent.id}`);
  return axiosPutPromise(url, adherent);
}

export function putAdherentPassword(adherentId, password) {
  const url = BASE_URL.concat(`adherent/${adherentId}/password`);
  return axiosPutPromise(url, password);
}

export function getCollectWeight(data) {
  const url = BASE_URL.concat(`collectWeight`);
  return axiosGetPromise(url, data);
}

export function postOrGetHarvest() {
  const url = BASE_URL.concat(`harvest`);
  return axiosGetPromise(url, { date: new Date() });
}

export function getCitiesByZipCode(adherentZipCode) {
  const url = BASE_URL.concat(`zipCode?cp=${adherentZipCode}`);

  return axiosGetPromise(url);
}

//* **********************************************
//*   Stats adherent routes
//* **********************************************
export function getAdherentStats(id, query = '') {
  let url = BASE_URL.concat(`adherent/${id}`);

  if (query !== '') {
    url += `?query=`;

    switch (query) {
      case 'yearMonth':
      case 'totalWeight':
      case 'collectListBySite':
        url += query;
        break;
      default:
    }
  }
  return axiosGet(url);
}

export function getAdherentStatsById(id) {
  const url = BASE_URL.concat(`adherent/${id}?query=yearMonth`);

  return axiosGet(url);
}
