import { combineReducers, createStore } from 'redux';
import { devToolsEnhancer } from 'redux-devtools-extension';
import adherentReducer from './adherent/reducer';
import adminReducer from './admin/reducer';

const rootReducer = combineReducers({
  adherent: adherentReducer,
  admin: adminReducer,
});

const store = createStore(rootReducer, devToolsEnhancer({}));

export default store;
