import { Route, useLocation } from 'react-router-dom';
import { CssBaseline, Container, Grid, Paper } from '@material-ui/core';

import NavBar from '../components/navbar/NavBar';
import Footer from '../components/footer/Footer';
import ContactUs from '../pages/ContactUs';
import Donnees from '../pages/Donnees';
import Bidon from '../pages/Accueil';
// import Deposits from '../pages/Deposits';
import Adhesion from '../pages/Adhesion';

import Ressources from '../pages/Ressources';
import FAQ from '../pages/FAQ';

import useStyles from '../styles/CSS';

const routes = [
  { path: `/home/accueil`, name: 'Accueil', Component: Bidon },
  {
    path: `/home/donnees`,
    name: 'Données',
    Component: Donnees,
  },
  {
    path: `/home/adhesion`,
    name: 'Adhesion',
    Component: Adhesion,
  },

  {
    path: `/home/ressources`,
    name: 'Ressources',
    Component: Ressources,
  },
  { path: '/home/contactus', name: 'Nous Contacter', Component: ContactUs },

  { path: '/home/faq', name: 'FAQ', Component: FAQ },
];

function Dashboard() {
  const classes = useStyles();
  const location = useLocation();

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <NavBar />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={3}>
              {/* Recent Orders */}
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  {routes.map(({ path, Component }) => (
                    <Route key={path} exact path={path} location={location}>
                      <Component />
                    </Route>
                  ))}
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </main>
        <div className={classes.appBarSpacer} />
      </div>
      <Footer />
    </>
  );
}

export default Dashboard;
