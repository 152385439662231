import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { TextField } from '@material-ui/core';
// import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { URL_API } from '../env';

import logoRond from '../images/logoRond.png';
import { login } from '../store/admin/actionCreator';
import { setAdherent } from '../store/adherent/actionCreator';

const axios = require('axios');

// css material UI
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },

  image: {
    backgroundColor: '#016634',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    width: '50%',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(5),
  },
  form: {
    width: '60%', // Fix IE 11 issue.
    marginTop: theme.spacing(4),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  cssLabel: {
    color: '#016634',
    '&.Mui-focused': {
      color: '#016634',
    },
  },

  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: '#016634 !important',
    },
  },

  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#016634 !important',
  },
}));
// end css Mayerial UI

function SignInPage() {
  const history = useHistory();
  const classes = useStyles();
  const [form, setForm] = useState({});
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const localToken = localStorage.getItem('TOKEN');
  const configAxios = {
    headers: {
      token: localToken,
    },
  };

  useEffect(() => {
    if (localToken) {
      axios
        .get(`${URL_API}verify`, configAxios)
        .then(() => {
          dispatch(login(localToken));
          history.push('/home/accueil');
        })
        .catch(() => {
          localStorage.clear();
        });
    } else {
      localStorage.clear();
    }
  }, [localToken]);

  const sendData = async (e) => {
    e.preventDefault();

    try {
      setError(null);
      const { data } = await axios.post(`${URL_API}authAdherent`, {
        email: form.email,
        password: form.password,
      });
      dispatch(login(data.token));
      dispatch(setAdherent(data.adherent));

      localStorage.setItem('TOKEN', data.token);
      localStorage.setItem('USER', data.adherent);
      history.push(`/home/accueil`);
    } catch (err) {
      setError(err);
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image}>
        <img
          src={logoRond}
          alt="Logo tree6clope"
          boxShadow={3}
          className={classes.logo}
        />
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Connexion
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="name"
              label="Identifiant"
              name="email"
              autoComplete="email"
              autoFocus
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              onChange={(e) => setForm({ ...form, email: e.target.value })}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Mot de passe"
              type="password"
              id="password"
              autoComplete="current-password"
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              onChange={(e) => setForm({ ...form, password: e.target.value })}
            />
            <Grid>
              {error && (
                <div>
                  <p
                    style={{
                      color: 'red',
                    }}
                  >
                    Mot de passe ou email incorrect
                  </p>
                </div>
              )}
            </Grid>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="#70696D"
              className={classes.submit}
              style={{ 'text-transform': 'none' }}
              onClick={(e) => sendData(e)}
            >
              S&apos;identifier
            </Button>
            <Grid container>
              <Grid item xs>
                <div variant="body1" className="flex-end">
                  <Button
                    style={{ 'text-transform': 'none' }}
                    href="mailto: contact@tree6clope.com?subject=Mot de passe oublié&body=Bonjour,
                    %0D%0APourriez-vous réinitialiser mon mot de passe.
                    %0D%0AMerci,
                    %0D%0ACordialement."
                  >
                    Mot de passe oublié
                  </Button>
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs>
                <div href="#" variant="body1" className="flex-end">
                  <Button
                    style={{ 'text-transform': 'none' }}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push('/inscription');
                    }}
                  >
                    Inscription
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}
export default SignInPage;
