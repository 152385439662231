import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Paper } from '@material-ui/core';
import pictogrammePoids from '../images/pictogrammePoids.png';
import logoMegot from '../images/logoMegot.png';
import pictogrammeCanard from '../images/pictogrammeCanard.png';
import { getAdherentStats } from '../api/apiBack';
import HarvestSiteRow from '../components/Accueil/HarvestSiteRow';

// Material css
const useStyles = makeStyles({
  root: {
    main: 'red',
  },
  titlePage: {
    textAlign: 'center',
    backgroundColor: '#1E6634',
    borderRadius: '0 0 20px 20px',
    margin: '0 80px',
    height: '50%',
    color: 'white',
    paddingTop: '20px',
  },
  intro: {
    textAlign: 'center',
    fontSize: '1rem',
    marginBottom: 0,
    marginTop: '30px',
  },
  subtitle: {
    textAlign: 'center',
    fontSize: '1.2rem',
    marginBottom: '20px',
    fontWeight: 'bold',
  },
  picto: {
    width: '70px',
    height: '70px',
  },
  card: {
    textAlign: 'center',
    padding: '10px',
    width: '250px',
    marginBottom: '10px',
  },
  gridContainer: {
    padding: '20px',
  },
  number: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    marginBottom: '5px',
  },
  banner: {
    backgroundColor: '#1E6634',
    borderRadius: '10px 0',
    color: 'white',
    height: '30px',
    margin: 0,
    paddingTop: '5px',
  },
  underBanner: {
    marginTop: 0,
  },
  button: {
    backgroundColor: '#1E6634',
    borderRadius: '6px',
    padding: '5px 10px',
    color: 'white',
    width: '100px',
  },
});
// end Material css

function Accueil() {
  const [isActive, setIsActive] = useState(false);
  const [totalWeight, setTotalWeight] = useState(0);
  const [collectList, setCollectList] = useState([]);
  const adherent = useSelector((state) => state.adherent.adherent);
  const token = localStorage.getItem('TOKEN');

  useEffect(() => {
    getAdherentStats(adherent.id, 'totalWeight').then((results) => {
      setTotalWeight(results.data[0].totalWeight);
    });
    getAdherentStats(adherent.id, 'collectListBySite').then((results) => {
      setCollectList(results.data[0].harvestSite);
    });
  }, [token, adherent]);

  const classes = useStyles();

  return (
    <>
      <div className={classes.main}>
        <h2 className={classes.titlePage}>
          BIENVENUE SUR VOTRE ESPACE TREE6CLOPE
        </h2>

        <Grid container>
          <Grid style={{ textAlign: 'center' }} xs={12}>
            <h3 style={{ fontSize: '1.5rem' }}>{adherent.sign}</h3>

            <p className={classes.intro}>
              Vous êtes adhérent Tree6clope depuis le{' '}
              {new Date(adherent.creationDate).toLocaleDateString()}.
            </p>
            <p style={{ marginTop: 0, fontSize: '1rem' }}>
              Consultez vos données de collecte et les ressources mises à votre
              disposition
            </p>
            <p className={classes.subtitle}>
              SYNTHÈSE DE VOS DONNÉES DE COLLECTE
            </p>
          </Grid>
          <Grid
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              flexWrap: 'wrap',
            }}
          >
            <Paper className={classes.card}>
              <img
                src={logoMegot}
                alt="Pictogramme poids"
                className={classes.picto}
              />
              <p className={classes.number}>{Math.round(totalWeight / 0.32)}</p>
              <p className={classes.banner}>MÉGOTS</p>
              <p className={classes.underBanner}>collectés</p>
            </Paper>

            <Paper className={classes.card}>
              <img
                src={pictogrammePoids}
                alt="Pictogramme poids"
                className={classes.picto}
              />
              <p className={classes.number}>
                {(totalWeight / 1000).toFixed(2)}
              </p>
              <p className={classes.banner}>KILOS</p>
              <p className={classes.underBanner}>
                de mégots collectés et recyclés
              </p>
            </Paper>

            <Paper className={classes.card}>
              <img
                src={pictogrammeCanard}
                alt="Pictogramme poids"
                className={classes.picto}
              />
              <p className={classes.number}>
                {((totalWeight / 0.32) * 0.005).toFixed(2)}
              </p>
              <p className={classes.banner}>m3 </p>
              <p className={classes.underBanner}>
                d&apos;eau potentiellement épargnés
              </p>
            </Paper>
          </Grid>
          <div style={{ textAlign: 'center', width: '100%' }}>
            <button
              type="button"
              className={classes.button}
              onClick={() => setIsActive(!isActive)}
            >
              {isActive ? 'Voir moins' : 'Voir plus'}
            </button>
            {isActive && (
              <HarvestSiteRow harvestSites={collectList} key={collectList.id} />
            )}
          </div>
        </Grid>
      </div>
    </>
  );
}

export default Accueil;
