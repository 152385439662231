import { Link } from 'react-router-dom';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
// eslint-disable-next-line no-unused-vars
import AssignmentIcon from '@material-ui/icons/Assignment';
import ExploreIcon from '@material-ui/icons/Explore';
// import AssessmentIcon from '@material-ui/icons/Assessment';
// eslint-disable-next-line no-unused-vars
import RoomIcon from '@material-ui/icons/Room';

const Items = [
  {
    route: `/home/accueil`,
    name: 'Accueil',
    icon: <DashboardIcon />,
  },
  {
    route: `/home/donnees`,
    name: 'Données',
    icon: <BarChartIcon />,
  },
  {
    route: `/home/adhesion`,
    name: 'Adhésion',
    icon: <PeopleIcon />,
  },
  {
    route: `/home/ressources`,
    name: 'Ressources',
    icon: <ShoppingCartIcon />,
  },
  { route: '/home/faq', name: 'FAQ', icon: <ExploreIcon /> },
  { route: '/home/contactus', name: 'Nous Contacter', icon: <PeopleIcon /> },
];

// eslint-disable-next-line import/prefer-default-export
export const mainListItems = (
  <div>
    {Items.map((item) => {
      return (
        <Link
          to={item.route}
          style={{ textDecoration: 'none', color: 'black' }}
        >
          <ListItem button>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.name} />
          </ListItem>
        </Link>
        // <ListItem button>
        //   <ListItemIcon>
        //     <Link to="/home/adherents">
        //       <PeopleIcon />
        //     </Link>
        //   </ListItemIcon>
        //   <Link to="/home/adherents">
        //     <ListItemText primary="Adhérents" />
        //   </Link>
        // </ListItem>
        // <ListItem button>
        //   <ListItemIcon>
        //     <Link to="/home/donnees">
        //       <PeopleIcon />
        //     </Link>
        //   </ListItemIcon>
        //   <Link to="/home/donnees">
        //     <ListItemText primary="Données" />
        //   </Link>
        // </ListItem>
        // <ListItem button>
        //   <ListItemIcon>
        //     <Link to="/home/map">
        //       <ExploreIcon />
        //     </Link>
        //   </ListItemIcon>
        //   <Link to="/home/map">
        //     <ListItemText primary="Map" />
        //   </Link>
        // </ListItem>
        // <ListItem button>
        //   <ListItemIcon>
        //     <ShoppingCartIcon />
        //   </ListItemIcon>
        //   <ListItemText primary="Articles" />
        // </ListItem>
        // <ListItem button>
        //   <ListItemIcon>
        //     <Link to="/home/statistiques">
        //       <BarChartIcon />
        //     </Link>
        //   </ListItemIcon>
        //   <Link to="/home/statistiques">
        //     <ListItemText primary="Statistiques" />
        //   </Link>
        // </ListItem>
      );
    })}
  </div>
);

/* export const secondaryListItems = (
  <div>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Collectes" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <RoomIcon />
      </ListItemIcon>
      <ListItemText primary="Points de collecte" />
    </ListItem>
  </div>
); */
