import {
  FormControl,
  InputLabel,
  makeStyles,
  Select,
  TextField,
} from '@material-ui/core';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-unused-vars
import { useSelector } from 'react-redux';
import { getCitiesByZipCode } from '../../api/apiBack';

const useStyles = makeStyles(() => ({
  root: {
    display: 'block',
  },
}));

// eslint-disable-next-line no-unused-vars
const FormZipCode = ({ onChange }) => {
  const classes = useStyles();
  const [zipCodes, setZipCodes] = useState([]);
  const [selectedZipCode, setSelectedZipCode] = useState('');

  const citiesPromiseSuccess = (result) => {
    // eslint-disable-next-line no-console
    console.log(result);
    setZipCodes(result.data);
  };

  const failPromise = () => {};

  // get cities by postal code
  const getCities = async (cp) => {
    const promiseCities = getCitiesByZipCode(cp);
    promiseCities.then(citiesPromiseSuccess, failPromise);
  };

  useEffect(() => {
    if (zipCodes.length > 0) {
      setSelectedZipCode(zipCodes[0]);
    }
  }, [zipCodes]);

  const handleZipCode = (e) => {
    if (e.target.value.length <= 5) {
      setSelectedZipCode(e.target.value);
    }

    if (e.target.value.length === 5) {
      getCities(e.target.value);
    }
  };

  return (
    <>
      {/* input zipCode */}
      <TextField
        type="text"
        label="Code Postal"
        value={selectedZipCode.zipCode}
        id="zipCode"
        name="zipCode"
        onChange={handleZipCode}
      />
      {/* select City */}
      <FormControl className={classes.root}>
        <InputLabel htmlFor="zipCodeId">City</InputLabel>
        <Select
          name="zipCodeId"
          value={selectedZipCode ? selectedZipCode.inseeId : 0}
          onChange={onChange}
        >
          {zipCodes.map((s) => (
            <option value={s.inseeId}>{s.city}</option>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

FormZipCode.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default FormZipCode;
