import { Box, Grid, makeStyles, Paper } from '@material-ui/core';
import colisRetour from '../ressources/demarches/colisRetour.pdf';
import guideInstallation from '../ressources/demarches/guideInstallationLabcBd.pdf';
import nouvelAdherentBbDistance1BC from '../ressources/demarches/nouvelAdherentBbDistance1BC.pdf';
import nouvelAdherentDistanceBd from '../ressources/demarches/nouvelAdherentDistanceBd.pdf';
import nouvelAdherentBbProximiteBC from '../ressources/demarches/nouvelAdherentBbProximiteBC.pdf';
import nouvelAdherentProximitéBd from '../ressources/demarches/nouvelAdherentProximitéBd.pdf';
import rollIUpPresentationTree6clope from '../ressources/sensibilise/rollIUpPresentationTree6clope.pdf';
import chosesASavoirSurLesMegots from '../ressources/sensibilise/chosesASavoirSurLesMegots.pdf';
import cycleDuRecyclegeBd from '../ressources/sensibilise/cycleDuRecyclegeBd.pdf';
import cycleMegotBd from '../ressources/sensibilise/cycleMegotBd.pdf';
import pochoirGenerique from '../ressources/communication/pochoirGenerique.pdf';
import pochoirAPersonnaliser from '../ressources/communication/pochoirAPersonnaliser.pdf';
import stickerGenerique10x15 from '../ressources/communication/stickerGenerique10x15.pdf';
import stickerVitrine from '../ressources/communication/stickerVitrine.pdf';

function Ressources() {
  const useStyles = makeStyles({
    titlePage: {
      textAlign: 'center',
      backgroundColor: '#1E6634',
      borderRadius: '0 0 20px 20px',
      margin: '0 80px',
      height: '50%',
      color: 'white',
      paddingTop: '20px',
    },
    link: {
      textDecoration: 'none',
      color: 'white',
    },
    iframe: {
      width: '100%',
      height: '100%',
    },
    box: {
      textAlign: 'center',
      width: '200px',
      height: '100%',
    },
    allBoxes: {
      display: 'flex',
      justifyContent: 'space-around',
      flexWrap: 'wrap',
      maxWidth: '100%',
    },
    paper: {
      padding: '10px',
      paddingBottom: '30px',
      marginBottom: '60px',
      backgroundColor: '#d8e9d1',
    },
    intro: {
      textAlign: 'center',
      fontSize: '1rem',
      marginBottom: '60px',
    },
    subtitle: {
      marginBottom: 0,
    },
    description: {
      marginTop: 0,
    },
    button: {
      backgroundColor: '#1E6634',
      borderRadius: '6px',
      padding: '5px 10px',
    },
    card: {
      marginBottom: '10px',
    },
  });

  const classes = useStyles();

  return (
    <div className={classes.main}>
      <h2 className={classes.titlePage}>MON CENTRE DE RESSOURCES</h2>
      <p className={classes.intro}>
        Retrouvez nos documents et outils pour vous accompagner dans votre
        démarche Zéro Mégot !
      </p>
      <h3 className={classes.subtitle}>JE M&apos;INFORME SUR MA DÉMARCHE</h3>
      <p className={classes.description}>
        Retrouvez l’ensemble des documents qui vous guideront dans votre action
        et vos démarches
      </p>
      <Paper className={classes.paper}>
        <Grid className={classes.allBoxes}>
          {/* start box Guide colis retour */}
          <Grid className={classes.box}>
            <Box
              boxShadow={1}
              bgcolor="background.paper"
              mx="auto"
              m={3}
              p={3}
              height="100%"
              className={classes.card}
            >
              <iframe
                src={colisRetour}
                title="Guide colis retour"
                className={classes.iframe}
              />
              Guide de retour colis mégots
            </Box>
            <button
              type="button"
              className={`${classes.button} ressourcesButton`}
            >
              <a
                href={colisRetour}
                download
                className={`ressourcesLink ${classes.link}`}
              >
                Télécharger
              </a>
            </button>
          </Grid>

          {/* start box Guide d'installation */}
          <Grid className={classes.box}>
            <Box
              boxShadow={1}
              bgcolor="background.paper"
              mx="auto"
              m={3}
              p={3}
              height="100%"
              className={classes.card}
            >
              <iframe
                src={guideInstallation}
                title="Guide d'installation"
                className={classes.iframe}
              />
              Guide d&apos;installation boîte à clopes
            </Box>
            <button
              type="button"
              className={`${classes.button} ressourcesButton`}
            >
              <a
                href={guideInstallation}
                download
                className={`ressourcesLink ${classes.link}`}
              >
                Télécharger
              </a>
            </button>
          </Grid>

          {/* start box nouvel adherent distance BC */}
          <Grid className={classes.box}>
            <Box
              boxShadow={1}
              bgcolor="background.paper"
              mx="auto"
              m={3}
              p={3}
              className={classes.card}
            >
              <iframe
                src={nouvelAdherentBbDistance1BC}
                title="Nouvel adherent distance boîte à clope"
                className={classes.iframe}
              />
              Nouvel adhérent à distance Boîte à clope
            </Box>
            <button
              type="button"
              className={`${classes.button} ressourcesButton`}
            >
              <a
                href={nouvelAdherentBbDistance1BC}
                download
                className={`ressourcesLink ${classes.link}`}
              >
                Télécharger
              </a>
            </button>
          </Grid>

          {/* start box nouvel adherent distance */}
          <Grid className={classes.box}>
            <Box
              boxShadow={1}
              bgcolor="background.paper"
              mx="auto"
              m={3}
              p={3}
              className={classes.card}
            >
              <iframe
                src={nouvelAdherentDistanceBd}
                title="Nouvel adhérent distance"
                className={classes.iframe}
              />
              Nouvel adhérent à distance
            </Box>
            <button
              type="button"
              className={`${classes.button} ressourcesButton`}
            >
              <a
                href={nouvelAdherentDistanceBd}
                download
                className={`ressourcesLink ${classes.link}`}
              >
                Télécharger
              </a>
            </button>
          </Grid>
          {/* start box nouvel adherent proximité BC */}
          <Grid className={classes.box}>
            <Box
              boxShadow={1}
              bgcolor="background.paper"
              mx="auto"
              m={3}
              p={3}
              className={classes.card}
            >
              <iframe
                src={nouvelAdherentBbProximiteBC}
                title="Nouvel adhérent proximité boîte à clope"
                className={classes.iframe}
              />
              Nouvel adhérent à proximité Boîte à clope
            </Box>
            <button
              type="button"
              className={`${classes.button} ressourcesButton`}
            >
              <a
                href={nouvelAdherentBbProximiteBC}
                download
                className={`ressourcesLink ${classes.link}`}
              >
                Télécharger
              </a>
            </button>
          </Grid>

          {/* start box nouvel adherent proximite */}
          <Grid className={classes.box}>
            <Box
              boxShadow={1}
              bgcolor="background.paper"
              mx="auto"
              m={3}
              p={3}
              className={classes.card}
            >
              <iframe
                src={nouvelAdherentProximitéBd}
                title="Nouvel adhérent à proximité"
                className={classes.iframe}
              />
              Nouvel adhérent à proximité
            </Box>
            <button
              type="button"
              className={`${classes.button} ressourcesButton`}
            >
              <a
                href={nouvelAdherentProximitéBd}
                download
                className={`ressourcesLink ${classes.link}`}
              >
                Télécharger
              </a>
            </button>
          </Grid>
        </Grid>
      </Paper>
      {/* end first section */}

      <h3 className={classes.subtitle}>JE M&apos;INFORME ET JE SENSIBILISE</h3>
      <p className={classes.description}>
        Consultez et téléchargez les documents adaptés pour sensibiliser votre
        personnel et/ou votre public
      </p>
      <Paper className={classes.paper}>
        <Grid className={classes.allBoxes}>
          <Grid className={classes.box}>
            {/* start box Presentation Tree6clope */}
            <Box
              boxShadow={1}
              bgcolor="background.paper"
              mx="auto"
              m={3}
              p={3}
              className={classes.card}
            >
              <iframe
                src={rollIUpPresentationTree6clope}
                title="Pésentation"
                className={classes.iframe}
              />
              Présentation Tree6clope - Dimension A4
            </Box>
            <button
              type="button"
              className={`${classes.button} ressourcesButton`}
            >
              <a
                href={rollIUpPresentationTree6clope}
                download
                className={`ressourcesLink ${classes.link}`}
              >
                Télécharger
              </a>
            </button>
          </Grid>

          {/* start box 6 choses à savoir sur les mégots */}
          <Grid className={classes.box} d>
            <Box
              boxShadow={1}
              bgcolor="background.paper"
              mx="auto"
              m={3}
              p={3}
              className={classes.card}
            >
              <iframe
                src={chosesASavoirSurLesMegots}
                title="6 choses à savoir sur les mégots"
                className={classes.iframe}
              />
              6 choses à savoir sur les mégots de cigarette - Dimension A4
            </Box>
            <button
              type="button"
              className={`${classes.button} ressourcesButton`}
            >
              <a
                href={chosesASavoirSurLesMegots}
                download
                className={`ressourcesLink ${classes.link}`}
              >
                Télécharger
              </a>
            </button>
          </Grid>

          {/* start box le cycle du mégot */}
          <Grid className={classes.box}>
            <Box
              boxShadow={1}
              bgcolor="background.paper"
              mx="auto"
              m={3}
              p={3}
              className={classes.card}
            >
              <iframe
                src={cycleMegotBd}
                title="Le cycle du mégot"
                className={classes.iframe}
              />
              &quot;Le cycle du mégot des sols aux océans&quot; - Dimension A4
            </Box>
            <button
              type="button"
              className={`${classes.button} ressourcesButton`}
            >
              <a
                href={cycleMegotBd}
                download
                className={`ressourcesLink ${classes.link}`}
              >
                Télécharger
              </a>
            </button>
          </Grid>

          {/* start box le cycle du recyclage */}
          <Grid className={classes.box}>
            <Box
              boxShadow={1}
              bgcolor="background.paper"
              mx="auto"
              m={3}
              p={3}
              className={classes.card}
            >
              <iframe
                src={cycleDuRecyclegeBd}
                title="Cycle du recyclage"
                className={classes.iframe}
              />
              Poster &quot;Le cycle du recyclage&quot; - Dimension A4
            </Box>
            <button
              type="button"
              className={`${classes.button} ressourcesButton`}
            >
              <a
                href={cycleDuRecyclegeBd}
                download
                className={`ressourcesLink ${classes.link}`}
              >
                Télécharger
              </a>
            </button>
          </Grid>
        </Grid>
      </Paper>
      {/* end second section */}

      <h3 className={classes.subtitle}>JE COMMUNIQUE SUR MA DÉMARCHE</h3>
      <p className={classes.description}>
        Vous souhaitez demander un balisage personnalisé ou des éléments de
        communication, c’est par ici !
      </p>
      <Paper className={classes.paper}>
        <Grid className={classes.allBoxes}>
          {/* start box pochoir générique */}
          <Grid className={classes.box}>
            <Box
              boxShadow={1}
              bgcolor="background.paper"
              mx="auto"
              m={3}
              p={3}
              className={classes.card}
            >
              <iframe
                src={pochoirGenerique}
                title="Pochoir générique"
                className={classes.iframe}
              />
              Pochoir générique
            </Box>
            <button
              type="button"
              className={`${classes.button} ressourcesButton`}
            >
              <a
                href="mailto: contact@tree6clope.com?subject=Commande pochoir générique"
                className={`ressourcesLink ${classes.link}`}
              >
                Demandez-le
              </a>
            </button>
          </Grid>

          {/* start box pochoir à personnaliser */}
          <Grid className={classes.box}>
            <Box
              boxShadow={1}
              bgcolor="background.paper"
              mx="auto"
              m={3}
              p={3}
              className={classes.card}
            >
              <iframe
                src={pochoirAPersonnaliser}
                title="Pochoir à personnaliser"
                className={classes.iframe}
              />
              Pochoir à personnaliser
            </Box>
            <button
              type="button"
              className={`${classes.button} ressourcesButton`}
            >
              <a
                href="mailto: contact@tree6clope.com?subject=Commande pochoir à personnaliser"
                className={`ressourcesLink ${classes.link}`}
              >
                Demandez-le
              </a>
            </button>
          </Grid>

          {/* start box */}
          <Grid className={classes.box}>
            <Box
              boxShadow={1}
              bgcolor="background.paper"
              mx="auto"
              m={3}
              p={3}
              className={classes.card}
            >
              <iframe
                src={stickerGenerique10x15}
                title="Sticker"
                className={classes.iframe}
              />
              Balisage générique
            </Box>
            <button
              type="button"
              className={`${classes.button} ressourcesButton`}
            >
              <a
                href="mailto: contact@tree6clope.com?subject=Commande balisage générique"
                className={`ressourcesLink ${classes.link}`}
              >
                Demandez-le
              </a>
            </button>
          </Grid>
          {/* start box sticker vitrine logo */}
          <Grid className={classes.box}>
            <Box
              boxShadow={1}
              bgcolor="background.paper"
              mx="auto"
              m={3}
              p={3}
              className={classes.card}
            >
              <iframe
                src={stickerVitrine}
                title="Sticker vitrine"
                className={classes.iframe}
              />
              Sticker Vitrine
            </Box>
            <button
              type="button"
              className={`${classes.button} ressourcesButton`}
            >
              <a
                href="mailto: contact@tree6clope.com?subject=Commande sticker vitrine&body=Bonjour"
                className={`ressourcesLink ${classes.link}`}
              >
                Demandez-le
              </a>
            </button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

export default Ressources;
