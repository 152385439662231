/* eslint-disable no-unused-vars */
import {
  Button,
  CssBaseline,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  Paper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
// eslint-disable-next-line no-unused-vars
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { getCitiesByZipCode } from '../api/apiBack';

import logoRond from '../images/logoRond.png';

import { URL_API } from '../env';
// eslint-disable-next-line no-unused-vars
import FormZipCode from '../components/Adherent/FormZipCode';

// css material UI
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },

  image: {
    backgroundColor: '#016634',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    width: '50%',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(5),
  },
  form: {
    width: '60%', // Fix IE 11 issue.
    marginTop: theme.spacing(4),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  cssLabel: {
    color: '#016634',
    '&.Mui-focused': {
      color: 'white',
      backgroundColor: '#016634',
    },
  },

  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: '#016634 !important',
    },
    backgroundColor: 'white',
  },

  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#016634 !important',
  },
}));

const initAdherent = {
  sign: '',
  legalEntity: '',
  streetNumber: '',
  streetName: '',
  postalBox: '',
  email: '',
  statusId: 1,
  typologyId: 1,
  zipCodeId: 0,
  distanceTypeId: 1,
  employeeQuantityId: 1,
};

function InscritionForm() {
  const [adherent, setAdherent] = useState(initAdherent);
  const [passwordCheck, setPasswordCheck] = useState('');
  const [zipCode, setZipCode] = useState();
  const [zipCodes, setZipCodes] = useState([]);
  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(`${URL_API}inscription`, {
        adherent,
      })
      .then(() => {
        history.push('/');
      });
  };

  // generic onChange for input text
  const handleField = (e) => {
    setAdherent((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  // end css Mayerial UI
  const classes = useStyles();

  const citiesPromiseSuccess = (result) => {
    setZipCodes(result.data);
    setAdherent((state) => ({ ...state, zipCodeId: result.data[0].inseeId }));
  };

  const failPromise = () => {};

  // get cities by postal code
  const getCities = async (cp) => {
    const promiseCities = getCitiesByZipCode(cp);
    promiseCities.then(citiesPromiseSuccess, failPromise);
  };

  const handleZipCode = (e) => {
    if (e.target.value.length <= 5) {
      setZipCode(e.target.Value);
    }

    if (e.target.value.length === 5) {
      getCities(e.target.value);
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image}>
        <img
          src={logoRond}
          alt="Logo tree6clope"
          boxShadow={3}
          className={classes.logo}
        />
      </Grid>

      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Inscription
          </Typography>
          <form onSubmit={handleSubmit} className={classes.form}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              type="text"
              id="sign"
              name="sign"
              label="Nom de l'entreprise"
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              onChange={handleField}
            />

            <TextField
              variant="outlined"
              margin="normal"
              required
              type="text"
              id="legalEntity"
              label="Raison Sociale"
              name="legalEntity"
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              onChange={handleField}
            />

            <TextField
              variant="outlined"
              margin="normal"
              required
              type="text"
              id="streetNumber"
              label="n° de la rue"
              name="streetNumber"
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              onChange={handleField}
            />

            <TextField
              variant="outlined"
              margin="normal"
              required
              type="text"
              id="streetName"
              name="streetName"
              label="Nom de la rue"
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              onChange={handleField}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              type="text"
              label="Code Postal"
              value={zipCode}
              id="zipCode"
              name="zipCode"
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              onChange={handleZipCode}
            />
            <Grid>
              <TextField
                value={adherent.zipCodeId}
                select
                required
                SelectProps={{
                  native: true,
                }}
                name="zipCodeId"
                label="Ville"
                onChange={handleField}
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              >
                {zipCodes.map((s) => (
                  <option value={s.inseeId}>{s.city}</option>
                ))}
              </TextField>
            </Grid>

            <TextField
              variant="outlined"
              margin="normal"
              required
              type="email"
              id="email"
              name="email"
              label="E-mail"
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              onChange={handleField}
            />

            <TextField
              variant="outlined"
              margin="normal"
              required
              type="password"
              id="password"
              name="password"
              label="Mot de passe"
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              onChange={handleField}
            />

            <TextField
              variant="outlined"
              margin="normal"
              required
              type="password"
              id="passwordCheck"
              name="passwordCheck"
              label="Répétez le mot de passe"
              value={passwordCheck}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              onChange={(e) => setPasswordCheck(e.target.value)}
            />
            <Grid>
              <Button
                variant="contained"
                color="#016634"
                className={classes.submit}
                type="submit"
                onClick={handleSubmit}
              >
                Valider
              </Button>
            </Grid>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}

export default InscritionForm;
