import { LOGIN, LOGOUT } from './actionTypes';

export const login = (token) => {
  return {
    type: LOGIN,
    payload: {
      token,
    },
  };
};

export const logout = (token) => {
  return {
    type: LOGOUT,
    payload: {
      token,
    },
  };
};
