import { Grid } from '@material-ui/core';
// import { useEffect, useState } from 'react';
import useStyles from '../styles/CSS';
// import FormDate from '../components/Graph/FormDate';
import BarGraphic from '../components/Graph/BarGraphic';

const Statistics = () => {
  const classes = useStyles();
  // const [dateStart, setDateStart] = useState(
  //   new Date('2000-01-01').toISOString()
  // );
  // const [dateEnd, setDateEnd] = useState(new Date().toISOString());
  return (
    <>
      <h2 className={classes.titlePage}>DONNÉES DE COLLECTE</h2>
      <div className={classes.appBarSpacer} />
      <Grid item xs={12}>
        <BarGraphic />
      </Grid>
      {/* <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormDate
              label="Date de départ"
              name="dateStart"
              disabled={false}
              selectedDate={dateStart}
              onChange={(e) => setDateStart(e.toISOString())}
            />
          </Grid>
          <Grid item xs={6}>
            <FormDate
              label="Date de fin"
              name="dateEnd"
              disabled={false}
              selectedDate={dateEnd}
              onChange={(e) => setDateEnd(e.toISOString())}
            />
          </Grid>
        </Grid>
      </Container> */}
    </>
  );
};

export default Statistics;
