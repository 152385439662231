import { makeStyles, Paper } from '@material-ui/core';
import Faq from 'react-faq-component';

const data = {
  rows: [
    {
      title:
        'Mon bac de stockage est trop petit / trop grand, je souhaite le changer,',
      content: `Si vous souhaitez changer de bac, vous pouvez en parler à notre chargé de collecte lors de son
      prochain passage ou lui envoyer un e-mail pour lui faire la demande. Il vous amènera le nouveau bac lors
      de son prochain passage.`,
    },
    {
      title: 'J’aimerais acquérir un cendrier, comment procéder ?',
      // real content :
      // content: `Depuis votre espace adhérent, dans l’onglet ressources, vous pouvez accéder à une fiche informative sur nos cendriers et nous soumettre un formulaire de bon de commande en cliquant sur « commander ». Nous traiterons ensuite votre demande et reviendrons vers vous par e-mail. Vous avez une question ? Contactez Alexandre pour en discuter.  `,
      // temporary content :
      content: `Vous pouvez faire votre demande par mail dans l'onglet "Nous contacter", nous traiterons ensuite votre demande et reviendrons vers vous par e-mail.`,
    },
    {
      title: 'Je souhaite personnaliser les questions de ma boite à clope',
      content: `Depuis l’onglet « ressources » de votre espace adhérent, vous pouvez nous soumettre votre pochoir à personnailiser.`,
    },
    {
      title:
        'A quoi sert le reçu fiscal ? Quel est le montant de la défiscalisation de mon adhésion ?',
      // content: `Depuis septembre 2020 Tree6clope est une association reconnue d’intérêt général, cela signifie que votre adhésion à l’association ouvre droit à une réduction d’impôt égale à 60% du montant de votre adhésion. Pour en bénéficier, rendez-vous dans l’onglet « mon adhésion » et cliquez sur « obtenir un reçu fiscal », nous traiterons votre demande et vous l’enverrons par e-mail.  `,
      content: `Depuis septembre 2020 Tree6clope est une association reconnue d’intérêt général, cela signifie que votre adhésion à l’association ouvre droit à une réduction d’impôt égale à 60% du montant de votre adhésion. Pour en bénéficier, contactez nous par mail dans l'onglet "Nous contacter", nous traiterons votre demande et vous l’enverrons par e-mail.`,
    },
    {
      title: 'Je souhaite faire un don, comment faire ?',
      content: `Vous pouvez nous faire un don et bénéficier d’une réduction fiscale. Dans l’attente de création d’un formulaire dédié, vous pouvez contacter Laurent et nous traiterons votre demande.  `,
    },
    {
      title:
        'Je souhaite sensibiliser mon personnel à l’impact des mégots, comment faire ?',
      content: `Dans l’onglet « ressources » de votre espace adhérent, vous pouvez accéder à plusieurs documents qui expliquent les différents enjeux autour des mégots et leur recyclage. Ceux-ci sont régulièrement actualisés. Si vous souhaitez aller plus loin et organiser un atelier de sensibilisation, contactez-nous.  `,
    },
    {
      title:
        'J’aimerais disposer d’autres outils de sensibilisation, que faire ?',
      content: `Nous pouvons vous proposer, sur demande, plusieurs formats d’ateliers et d’interventions pour sensibiliser votre personnel ou votre public. Vous pouvez faire part de ces demandes à Cécile. `,
    },
  ],
};

const styles = {
  bgColor: '#d8e9d1',
  rowTitleColor: 'black',
  rowContentTextSize: '0.9rem',
};
const config = {
  animate: true,
};

function FAQ() {
  const useStyles = makeStyles({
    titlePage: {
      textAlign: 'center',
      backgroundColor: '#1E6634',
      borderRadius: '0 0 20px 20px',
      margin: '0 80px',
      height: '50%',
      color: 'white',
      paddingTop: '20px',
    },
    intro: {
      textAlign: 'center',
      fontSize: '1rem',
      marginBottom: '30px',
      marginTop: 0,
    },
    subtitle: {
      textAlign: 'center',
      fontSize: '1.5rem',
      color: '#1E6634',
      marginBottom: 0,
    },
    bloc: {
      backgroundColor: '#d8e9d1',
      padding: '20px',
    },
  });

  const classes = useStyles();
  return (
    <>
      <h2 className={classes.titlePage}>QUESTIONS FRÉQUENTES</h2>
      <h3 className={classes.subtitle}>VOS QUESTIONS, NOS RÉPONSES</h3>
      <p className={classes.intro}>
        Retrouvez ici les questions fréquentes posées par nos adhérents
      </p>
      <Paper className={classes.bloc}>
        <Faq data={data} styles={styles} config={config} />
      </Paper>
    </>
  );
}

export default FAQ;
