import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAdherentStatsById } from '../../api/apiBack';
import BarGraphicComponent from './BarGraphicComponent';

const token = localStorage.getItem('TOKEN');

export default function BarGraphic() {
  const [hasDatas, setHasDatas] = useState(false);
  const [datas, setDatas] = useState([]);
  const adherent = useSelector((state) => state.adherent.adherent);

  useEffect(() => {
    if (adherent) {
      getAdherentStatsById(adherent.id).then((results) => {
        if (results.data.length !== 0) {
          setDatas(results.data);
        }
      });
    }
  }, [token, adherent]);

  useEffect(() => {
    if (datas.length !== 0) {
      setHasDatas(true);
    }
  }, [datas]);

  return (
    <>
      {!hasDatas ? (
        <h3>Aucunes données collectées pour le moment</h3>
      ) : (
        <BarGraphicComponent datas={datas} />
      )}
    </>
  );
}
