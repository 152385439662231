import { useState } from 'react';
import { useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { putAdherentPassword } from '../../api/apiBack';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs() {
  const [open, setOpen] = useState(false);
  const adherent = useSelector((state) => state.adherent.adherent);
  const [firstPassword, setFirstPassword] = useState('');
  const [secondPassword, setSecondPassword] = useState('');
  const [error, setError] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFirstPassword('');
    setSecondPassword('');
    setError(false);
  };

  const handleChangeFirstPassword = (e) => {
    setFirstPassword(e.target.value);
  };

  const handleChangeSecondPassword = (e) => {
    setSecondPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (firstPassword.length === 0) {
      setError(true);
    } else if (secondPassword.lenght === 0) {
      setError(true);
    } else if (firstPassword !== secondPassword) {
      setError(true);
    } else if (firstPassword === secondPassword) {
      putAdherentPassword(adherent.id, { password: secondPassword }).then(
        () => {
          handleClose();
        }
      );
    }
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <button
        style={{
          backgroundColor: '#1E6634',
          borderRadius: '6px',
          padding: '5px 10px',
          color: 'white',
          width: '190px',
          margin: '20px',
        }}
        type="submit"
        onClick={handleClickOpen}
      >
        {' '}
        Changer nom mot de passe
      </button>

      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Nouveau mot de passe
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            id="outlined-basic"
            label="Nouveau mot de passe"
            variant="outlined"
            type="password"
            value={firstPassword}
            onChange={handleChangeFirstPassword}
            style={{ margin: '10px', height: '20%' }}
          />
          <TextField
            id="outlined-basic"
            label="Confirmer mot de passe"
            variant="outlined"
            type="password"
            value={secondPassword}
            onChange={handleChangeSecondPassword}
            style={{ margin: '10px' }}
          />
          {error && (
            <p style={{ color: 'red', margin: 0, marginLeft: '10px' }}>
              Mot de passe incorrect
            </p>
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleSubmit} color="primary">
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
