import PropTypes from 'prop-types';
import { TableBody, TableCell, TableRow } from '@material-ui/core';

// eslint-disable-next-line react/prop-types
function HarvestDateRow({ label, harvests }) {
  return (
    <TableBody>
      {
        // eslint-disable-next-line react/prop-types
        harvests.map((h) => {
          return (
            <TableRow>
              <TableCell align="center">
                {new Date(h.harvest.date).toLocaleDateString()}
              </TableCell>
              <TableCell align="center">{label}</TableCell>
              <TableCell align="center">{h.weight}gr</TableCell>
            </TableRow>
          );
        })
      }
    </TableBody>
  );
}
HarvestDateRow.propTypes = {
  label: PropTypes.string.isRequired,
  harvests: PropTypes.shape({
    weight: PropTypes.number.isRequired,
    harvest: PropTypes.shape({
      date: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default HarvestDateRow;
