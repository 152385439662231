import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router-dom';
import InscriptionForm from './pages/InscriptionForm';
import { URL_API } from './env';

import Dashboard from './layout/Dashboard';
import SignInPage from './pages/SignInPage';

import { LOGIN } from './store/admin/actionTypes';

// eslint-disable-next-line no-unused-vars
const jwt = require('jsonwebtoken');

export default function Router() {
  // eslint-disable-next-line no-unused-vars
  const history = useHistory();
  const token = useSelector((state) => state.admin.token);
  const localToken = localStorage.getItem('TOKEN');
  const [isLogged, setIsLogged] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      setIsLogged(true);
    } else if (!localToken) {
      setIsLogged(false);
    } else if (localToken) {
      axios
        .get(`${URL_API}verify`, {
          headers: {
            token: localToken,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then((res) => {
          dispatch({
            type: LOGIN,
            payload: {
              token: localToken,
            },
          });
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          //  axios va directement dans le .catch et donc il faut clear le local
          //  storage et ca devrait renvoyer directment sur la page auth sinon
          //  history push vers /

          localStorage.clear();
          setIsLogged(false);
        });
    } else {
      setIsLogged(false);
    }
  }, [token, localToken]);

  return (
    <>
      {isLogged ? (
        <Switch>
          <Route path="/" component={Dashboard} />
        </Switch>
      ) : (
        <Switch>
          <Route exact path="/inscription" component={InscriptionForm} />
          <SignInPage exact path="/" />
        </Switch>
      )}
    </>
  );
}
