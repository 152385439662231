import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import laurent from '../images/laurentDonse.png';
import cecile from '../images/cecileTonnerre.png';
import alexandre from '../images/alexandreGriveau.png';
import momal from '../images/momalZerouali.png';

// Generate Order Data
/* function createData(id, picture, name, title, text, mail) {
  return { id, picture, name, title, text, mail };
} */

// eslint-disable-next-line no-unused-vars
const rows = [
  {
    id: 0,
    pic: laurent,
    name: 'Laurent Donse',
    title: "Président de l'association",
    text:
      'C’est lui qui est à l’origine du projet Tree6clope. Depuis 2016, il chapôte les activités de la structure et s’occupe à peu près de tout! De la stratégie, au pilotage du projet de revalorisation, du terrain à l’administration, il est sur tous les fronts.',
    email: 'laurent@tree6clope.com',
  },
  {
    id: 1,
    pic: cecile,
    name: 'Cecile Tonnerre',
    title: 'Responsable communication',
    text:
      "Elle alimente la communication de l'association (réseaux sociaux, site internet, relations presse, newsletter). Elle assure le rayonnement de l'association et gère aussi les actions de sensibilisation et le développement d'outils pédagogiques",
    email: 'cecile@tree6clope.com',
  },
  {
    id: 2,
    pic: alexandre,
    name: 'Alexandre Griveau',
    title: 'Responsable développement',
    text:
      'Il est votre premier contact à Tree6clope. Son rôle est de développer le réseau d’adhérents, entretenir les relations avec vous, vous fournir la meilleure offre d’outils de collecte et développer des partenariats.',
    email: 'alexandre@tree6clope.com',
  },
  {
    id: 3,
    pic: momal,
    name: 'Momal Zerouali',
    title: 'Responsable collecte',
    text:
      ' Il organise, planifie les tournées de collecte et les assure. Il répond à vos questions et fais un lien avec vous au quotidien. C’est votre contact privilégié sur le terrain.',
    email: 'momal@tree6clope.com',
  },
];

/* function preventDefault(event) {
  event.preventDefault();
} */

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  titlePage: {
    textAlign: 'center',
    backgroundColor: '#016634',
    borderRadius: '0 0 20px 20px',
    margin: '0 80px',
    height: '50%',
    color: 'white',
    paddingTop: '20px',
  },
  intro: {
    textAlign: 'center',
    fontSize: '1rem',
    marginBottom: '60px',
  },
  card: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  box: {
    maxWidth: '450px',
    marginBottom: '40px',
  },
  link: {
    textDecoration: 'none',
    color: 'white',
  },
  button: {
    backgroundColor: '#016634',
    borderRadius: '6px',
    padding: '5px 10px',
  },
  insideCard: {
    display: 'flex',
  },
  img: {
    width: '150px',
    height: '150px',
    margin: 'auto',
  },
  description: {
    marginLeft: '38px',
  },
  bottomText: {
    textAlign: 'center',
    backgroundColor: '#016634',
    margin: 'auto',
    borderRadius: '20px',
    color: 'white',
    width: '50%',
  },
}));

export default function Orders() {
  const classes = useStyles();

  return (
    <>
      <div className={classes.main}>
        <h2 className={classes.titlePage}>L&apos;ÉQUIPE TREE6CLOPE</h2>
        <p className={classes.intro}>
          Si vous souhaitez nous faire part de vos projets, nous rencontrer,
          contactez-nous !
        </p>
        <Grid container>
          <Grid className={classes.card}>
            {rows.map((e) => (
              <div
                mx="auto"
                m={3}
                p={3}
                className={classes.box}
                // style={{ width: '8rem', height: '5rem' }}
              >
                <div className={`insideCard ${classes.insideCard}`}>
                  <img src={e.pic} alt="profilepic" className={classes.img} />
                  <div className={classes.description}>
                    <h2 style={{ marginBottom: 0, color: '#036534' }}>
                      {e.name}
                    </h2>
                    <h3 style={{ marginTop: 0 }}>{e.title}</h3>
                    <h4 style={{ fontSize: '0.8rem' }}>{e.text}</h4>
                  </div>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <button type="button" className={classes.button}>
                    <a
                      href={`mailto:${e.email}`}
                      className={`ressourcesLink ${classes.link}`}
                    >
                      Je lui écris
                    </a>
                  </button>
                </div>
              </div>
            ))}
          </Grid>
        </Grid>
        <div className={classes.bottomText}>
          <p style={{ marginBottom: 0, paddingTop: '5px' }}>
            Vous pouvez aussi nous joindre au :{' '}
            <a href="tel:0673733156" style={{ color: 'white' }}>
              06.73.73.31.56
            </a>
          </p>
          <p style={{ marginTop: 0, paddingBottom: '5px' }}>
            Notre adresse : 7 place Beaurivage 64200 Biarritz
          </p>
        </div>
      </div>
    </>
  );
}
