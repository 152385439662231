import PropTypes from 'prop-types';
import {
  makeStyles,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import HarvestDateRow from './HarvestDateRow';

const useStyles = makeStyles({
  table: {
    width: '70%',
    margin: 'auto',
    marginTop: '20px',
  },
  labelHead: {
    backgroundColor: '#d8e9d1',
  },
});

function HarvestSiteRow({ harvestSites }) {
  const classes = useStyles();

  return (
    <TableContainer className={classes.table} component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow className={classes.labelHead}>
            <TableCell align="center" style={{ width: '20%' }} colSpan={1}>
              Dates
            </TableCell>
            <TableCell align="center" style={{ width: '20%' }} colSpan={1}>
              Point de collecte
            </TableCell>
            <TableCell align="center" style={{ width: '20%' }} colSpan={1}>
              Poids
            </TableCell>
          </TableRow>
        </TableHead>
        {harvestSites.map((h) => (
          <HarvestDateRow label={h.label} harvests={h.harvest} key={h.label} />
        ))}
      </Table>
    </TableContainer>
  );
}

HarvestSiteRow.propTypes = {
  harvestSites: PropTypes.arrayOf.isRequired,
};

export default HarvestSiteRow;
