import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import {
  Drawer,
  List,
  Divider,
  IconButton,
  Toolbar,
  AppBar,
  Typography,
} from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { FaPowerOff } from 'react-icons/fa';
import { mainListItems /* secondaryListItems */ } from './ListItems';

import useStyles from '../../styles/CSS';
import { LOGOUT } from '../../store/admin/actionTypes';

import logo from '../../images/logo.png';

function NavBar() {
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const dispatch = useDispatch();

  const handleDrawer = () => {
    setOpen(!open);
  };

  const logout = () => {
    dispatch({
      type: LOGOUT,
      payload: {
        token: null,
      },
    });
    localStorage.removeItem('TOKEN');
    history.push('/');
  };

  return (
    <>
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawer}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Dashboard
          </Typography>
          <IconButton onClick={logout}>
            <FaPowerOff size="1.2rem" color="white" title="Deconnexion" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <img src={logo} alt="logo tree6clope" width="150px" />
          <IconButton onClick={handleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems}</List>
        <Divider />
        {/* <List>{secondaryListItems}</List> */}
      </Drawer>
    </>
  );
}

export default NavBar;
